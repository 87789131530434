import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public isHomePage = signal<boolean>(false);
  constructor(private http: HttpClient) {}
}
